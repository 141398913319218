// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-image {
    height: 100vh;
}
@media (max-width:620px) {
    .faq-image{
        height: 50vh;
    }
}

.text-main-faq {
    font-size: 56px;
}


.txtmgrnTop{
    margin-top: 31%;
}
@media (max-width:620px) {
    .text-main-faq{
        font-size: 40px;
    }

    .txtmgrnTop{
        margin-top: 115%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/faq.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;IACI,eAAe;AACnB;;;AAGA;IACI,eAAe;AACnB;AACA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".faq-image {\n    height: 100vh;\n}\n@media (max-width:620px) {\n    .faq-image{\n        height: 50vh;\n    }\n}\n\n.text-main-faq {\n    font-size: 56px;\n}\n\n\n.txtmgrnTop{\n    margin-top: 31%;\n}\n@media (max-width:620px) {\n    .text-main-faq{\n        font-size: 40px;\n    }\n\n    .txtmgrnTop{\n        margin-top: 115%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
