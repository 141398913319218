// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.head1{
    font-size: 20px !important;
    font-weight: 700 !important;
    color: #000000;
    padding-top: 10px;
}
.recom-text{
    font-size: 16px;
    font-weight: 600;
    color: #008DCD;
    padding-top: 5px;
}
.address-text{
    font-size: 16px;
    font-weight: 700;
    color: #7F7F7F;
line-height: 24px;
    padding-top: 10px;
}
.addressfull-text{
    font-size: 16px;
    font-weight: 400;
    color: #000000;

    line-height: 24px;
}

.address-text1{
    font-size: 30px !important;
    font-weight: 600 !important;
    color: #2E2829;

   
}
.addressfull-text2{
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    padding-top: 5px;
    line-height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Starter/Starter/starter.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,2BAA2B;IAC3B,cAAc;IACd,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB,iBAAiB;IACb,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;;IAEd,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;IAC1B,2BAA2B;IAC3B,cAAc;;;AAGlB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".head1{\n    font-size: 20px !important;\n    font-weight: 700 !important;\n    color: #000000;\n    padding-top: 10px;\n}\n.recom-text{\n    font-size: 16px;\n    font-weight: 600;\n    color: #008DCD;\n    padding-top: 5px;\n}\n.address-text{\n    font-size: 16px;\n    font-weight: 700;\n    color: #7F7F7F;\nline-height: 24px;\n    padding-top: 10px;\n}\n.addressfull-text{\n    font-size: 16px;\n    font-weight: 400;\n    color: #000000;\n\n    line-height: 24px;\n}\n\n.address-text1{\n    font-size: 30px !important;\n    font-weight: 600 !important;\n    color: #2E2829;\n\n   \n}\n.addressfull-text2{\n    font-size: 13px;\n    font-weight: 400;\n    color: #000000;\n    padding-top: 5px;\n    line-height: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
