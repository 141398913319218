import React, { useEffect, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import MessageSelf from "./MessageSelf";
import MessageOthers from "./MessageOthers";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import DeleteChatModal from "./DeleteChatModal";

import { io } from "socket.io-client";
import {
  GetMessageApi,
  chatListApi,
  sendMessageApi,
} from "../../_redux/features/messageSlice";
import { chatURL } from "../../_redux/axios/axios";

const ENDPOINT = chatURL;
var socket, chat;

function ChatArea({
  MessageToUser,
  conversation,
  setscreenCount,
  setConversation,
  putChatOnTop,
  conversations,
  setConversations,
}) {
  const [messageContent, setMessageContent] = useState("");
  const messagesEndRef = useRef(null);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [allMessages, setAllMessages] = useState([]);

  const { MessagesList } = useSelector((state) => state.message);

  const [loaded, setloaded] = useState(false);
  const [socketConnectionStatus, setSocketConnectionStatus] = useState(false);
  const dispatch = useDispatch();
  const sendMessage = async () => {
    putChatOnTop(conversation?._id);
    await dispatch(
      sendMessageApi({
        content: messageContent,
        chatId: conversation?._id,
        senderId: userData?.user?._id,
      })
    ).then((response) => {
      dispatch(GetMessageApi(conversation._id));
      setMessageContent("");
      socket.emit("new message", response.payload);
    });
    setConversations((prevConversations) =>
      prevConversations.map((conv) =>
        conv._id === conversation._id
          ? {
              ...conv,
              latestMessage: {
                content: messageContent,
                senderId: userData?.user?._id,
                timestamp: new Date().toISOString(),
              },
            }
          : conv
      )
    );
  };

  useEffect(() => {
    socket = io(ENDPOINT);
    socket.emit("setup", userData);
    socket.on("connected", () => {
      console.log("Connected to server");
      setSocketConnectionStatus(true);
    });

    socket.on("message received", (newMessage) => {
      setAllMessages((prevMessages) => [...prevMessages, newMessage]);
    });

    return () => {
      socket.off("connected");
      socket.off("message received");
    };
  }, []);

  useEffect(() => {
    const getsms = async () => {
      await dispatch(GetMessageApi(conversation?._id)).then((data) => {
        setAllMessages(data.payload);

        setloaded(true);
        socket.emit("join chat", conversation?._id);
      });
    };
    getsms();
    // scrollToBottom();
  }, [conversation?._id, userData.token]);

  useEffect(() => {
    if (MessagesList) {
      setAllMessages(MessagesList);
    }
  }, [MessagesList]);
  const [showDeleteChatModal, setShowDeleteChatModal] = useState(false);
  const showDeleteChatScreen = () => {
    toggleDeleteChatModal();
  };
  const toggleDeleteChatModal = () => {
    setShowDeleteChatModal(!showDeleteChatModal);
  };
  if (!loaded) {
    return (
      <div
        style={{
          border: "20px",
          padding: "10px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Skeleton
          variant="rectangular"
          sx={{ width: "100%", borderRadius: "10px" }}
          height={60}
        />
        <Skeleton
          variant="rectangular"
          sx={{
            width: "100%",
            borderRadius: "10px",
            flexGrow: "1",
          }}
        />
        <Skeleton
          variant="rectangular"
          sx={{ width: "100%", borderRadius: "10px" }}
          height={60}
        />
      </div>
    );
  } else {
    return (
      <div className={"chatArea-container "}>
        {showDeleteChatModal ? (
          <div className="modalDeleteChat" style={{ zIndex: 999 }}>
            <div className="modal-contentDeleteChat">
              <DeleteChatModal
                closeWindow={toggleDeleteChatModal}
                chatId={conversation._id}
                setscreenCount={setscreenCount}
                setConversation={setConversation}
              />
            </div>
          </div>
        ) : (
          <div className={"chatArea-header"}>
            {/* <p className={"con-icon"}>{conversation?.users[1].name[0]}</p> */}
            <img
              className={"con-icon"}
              src="https://w7.pngwing.com/pngs/910/606/png-transparent-head-the-dummy-avatar-man-tie-jacket-user-thumbnail.png"
              alt="dummy"
            ></img>
            <div className={"header-text"}>
              <p className={"con-title"}>{conversation?.users[1].name}</p>
              {/* <p className={"con-timeStamp" }>
              {props.timeStamp}
            </p> */}
            </div>
            <IconButton className={"icon"}>
              <DeleteIcon onClick={showDeleteChatScreen} />
            </IconButton>
          </div>
        )}

        <div className={"messages-container"}>
          {allMessages
            ?.slice(0)
            .reverse()
            .map((message, index) => {
              const sender = message?.sender;

              const self_id = userData?.user?._id;
              if (sender?._id === self_id) {
                return <MessageSelf props={message} key={index} />;
              } else {
                return <MessageOthers props={message} key={index} />;
              }
            })}
        </div>
        <div ref={messagesEndRef} className="BOTTOM" />
        <div className={"text-input-area"}>
          <input
            placeholder=""
            className={"send-box"}
            value={messageContent}
            onChange={(e) => {
              setMessageContent(e.target.value);
            }}
            onKeyDown={(event) => {
              if (event.code == "Enter") {
                sendMessage();
                setMessageContent("");
                // setRefresh(!refresh);
              }
            }}
          />
          <IconButton
            className={"icon"}
            onClick={() => {
              sendMessage();
            }}
          >
            <SendIcon />
          </IconButton>
        </div>
      </div>
    );
  }
}

export default ChatArea;
