// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ReviewStar.css */
.star {
    font-size: 33px; /* Adjust the size of the stars */
    color: gold; /* Change the color of filled stars */
  }
  
  .news-details-img {
    height: 400px;
  }

  .details-text{
    font-size: 36px;
  }
  
  .details-desc{
    font-size: 20px;
  }
  @media (max-width:620px) {
    .news-details-img {
      height: 200px;
    }
    .details-text{
      font-size: 24px;
    }
    .details-desc{
      font-size: 16px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/Pages/Firms/FirmDetails/style.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,eAAe,EAAE,iCAAiC;IAIlD,WAAW,EAAE,qCAAqC;EAHpD;;EAMA;IACE,aAAa;EACf;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;EACA;IACE;MACE,aAAa;IACf;IACA;MACE,eAAe;IACjB;IACA;MACE,eAAe;IACjB;EACF","sourcesContent":["/* ReviewStar.css */\n.star {\n    font-size: 33px; /* Adjust the size of the stars */\n  }\n \n  .star {\n    color: gold; /* Change the color of filled stars */\n  }\n  \n  .news-details-img {\n    height: 400px;\n  }\n\n  .details-text{\n    font-size: 36px;\n  }\n  \n  .details-desc{\n    font-size: 20px;\n  }\n  @media (max-width:620px) {\n    .news-details-img {\n      height: 200px;\n    }\n    .details-text{\n      font-size: 24px;\n    }\n    .details-desc{\n      font-size: 16px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
