import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import IMG from "../../../assets/file/img.png";
import IMG2 from "../../../assets/file/pdf.jpg";
import IMG3 from "../../../assets/file/docx.png";
import addCas from "../../../assets/addCase.jpg";
import { Tooltip } from "@mui/material";

import {
  getTeamLaywer,
  getLawyerType,
  UpdateCase,
  allCasesbyUser,
  getNews,
  getServicesByID,
} from "../../../_redux/features/firm/firmSlice";

import { toast } from "react-toastify";

const EditCaseScreen = ({ item, closeModal, Id, ModalIsOpen }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [updateCase, setUpdateCase] = useState({
    id: item._id,
    title: item?.title || "",
    description: item.description || "",
    file: item.file || "",
    user_id: item?.user?._id,
    service_id: item?.service_id?._id || "",
  });
  useEffect(() => {
    setUpdateCase({
      id: item._id,
      title: item?.title || "",
      description: item.description || "",
      file: item.file || "",
      user_id: item?.user?._id,
      service_id: item?.service_id?._id || "",
    });
  }, [item]);
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    file: "",
  });
  const { firm } = useSelector((state) => state.firm);

  const dispatch = useDispatch();
  const [servicesData, setServicesData] = useState([]);
  useEffect(() => {
    const getService = async () => {
      await dispatch(getServicesByID({ user_id: user?.user?._id })).then(
        (res) => {
          const services = res?.payload?.data;
          setServicesData(services);
          if (services?.length > 0) {
            setUpdateCase((prevState) => ({
              ...prevState,
              service_id: services[0]._id,
            }));
          }
        }
      );
    };
    getService();
  }, [user?.user?._id, dispatch]);
  useEffect(() => {
    const getData = async () => {
      await dispatch(getTeamLaywer());
      await dispatch(getLawyerType());
    };
    getData();
  }, [dispatch]);

  const handleChange = (e) => {
    setUpdateCase((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (firm?.TeamLawyer?.teamLayer?.length > 0) {
      setUpdateCase((prevState) => ({
        ...prevState,
        lawer_id: firm.TeamLawyer.teamLayer[0]._id,
      }));
    }
    if (firm?.LawyerType?.data?.length > 0) {
      setUpdateCase((prevState) => ({
        ...prevState,
        laywer_type: firm?.LawyerType?.data[0]._id,
      }));
    }
  }, [firm?.TeamLawyer, firm?.LawyerType?.data]);

  const UpdateNewsSave = async () => {
    const formData = new FormData();

    for (let key in updateCase) {
      formData.append(key, updateCase[key]);
    }

    await dispatch(UpdateCase(formData)).then(async () => {
      await dispatch(getTeamLaywer());
      await dispatch(getLawyerType());
      await dispatch(allCasesbyUser({ user_id: user?.user?._id }));
      await dispatch(getNews());
    });

    closeModal();
    toast.success("Case Details Updated Successfully", {
      autoClose: 1000,
    });
  };
  const fileInputRef = useRef(null);

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const [userImage, setuserImage] = useState();

  const getFileIcon = (fileType) => {
    if (fileType.startsWith("image/")) {
      return URL.createObjectURL(userImage || "");
    } else if (fileType === "application/pdf") {
      return IMG2;
    } else if (
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return IMG3;
    } else {
      return "path/to/default-icon.png";
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (
      file.type.startsWith("image/") ||
      file.type === "application/pdf" ||
      file.type === "application/msword" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setuserImage(file);
      setUpdateCase((prevState) => ({
        ...prevState,
        file: file,
      }));
    } else {
      toast.info("Please select a valid image, PDF, or Word document.");
      event.target.value = null;
    }
  };

  return (
    <>
      <Modal
        isOpen={ModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {/* Close buttom */}
        <div className="row justify-content-end">
          <button className="btn-close" onClick={closeModal}></button>
        </div>
        {/* Heading */}
        <div className="row text-start">
          <p className="poppins-600 h3">Edit Case Details</p>
        </div>

        {/* main form */}

        <div className="row mt-0 d-flex align-items-center">
          <div className="col-md-7 col-sm-12">
            <div className="row text-start">
              <div className="form-group mt-3">
                <label htmlFor="title" className="poppins-500">
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  className="form-control my-2"
                  style={{ outline: "none" }}
                  onChange={handleChange}
                  value={updateCase?.title}
                />
                {errors.title && (
                  <span className="text-danger">{errors.title}</span>
                )}
              </div>

              <div className="form-group mt-3">
                <label htmlFor="service_id" className="poppins-300">
                  Add Category
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="service_id"
                  value={updateCase?.service_id}
                  onChange={handleChange}
                >
                  {servicesData?.map((service, i) => (
                    <option key={i} value={service._id}>
                      {service.title}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group my-4">
                <label htmlFor="description" className="poppins-500">
                  Description
                </label>
                <textarea
                  className="form-control my-2"
                  style={{ outline: "none" }}
                  name="description"
                  id="description"
                  rows="6"
                  value={updateCase?.description}
                  onChange={handleChange}
                ></textarea>
                {errors.description && (
                  <span className="text-danger">{errors.description}</span>
                )}
              </div>
            </div>
          </div>
          <div
            className="col-md-5 col-sm-12 d-flex justify-content-center"
            onClick={handleAvatarClick}
            style={{ cursor: "pointer" }}
          >
            {!userImage ? (
              <Tooltip title="Select File" className="fs-3">
                <img
                  src={addCas}
                  className="img-fluid border"
                  style={{ position: "relative", width: "50%", height: "30%" }}
                  alt="Selected File"
                />
              </Tooltip>
            ) : userImage ? (
              <img
                src={getFileIcon(userImage.type)}
                className="img-fluid"
                style={{ position: "relative", width: "50%", height: "30%" }}
                alt="Selected File"
              />
            ) : (
              <img
                src="path/to/default-placeholder.png"
                className="img-fluid"
                style={{ position: "relative", width: "50%", height: "30%" }}
                alt="Select File"
              />
            )}

            <input
              className=""
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept="image/*,.pdf,.doc,.docx,application/pdf,application/msword"
            />
            {errors.file && <span className="text-danger">{errors.file}</span>}
          </div>
        </div>

        {/* Submit buttom */}
        <div className="text-center mt-4 mb-4">
          <button className="btn btn-dark" onClick={UpdateNewsSave}>
            Update
          </button>
        </div>
      </Modal>
    </>
  );
};

export default EditCaseScreen;
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "4rem",
    borderRadius: "20px",
    transform: "translate(-50%, -50%)",
  },
};
