// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.star {
    font-size: 2rem;
    border-radius: 5px;
    color: #DEDEDE;
  }
  
  .filled {
    color: #FCE300;
  }`, "",{"version":3,"sources":["webpack://./src/Styles/reviewStar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".star {\n    font-size: 2rem;\n    border-radius: 5px;\n    color: #DEDEDE;\n  }\n  \n  .filled {\n    color: #FCE300;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
