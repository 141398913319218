import girlImg from "../../assets/lawyer-assets/mainLawyer.png";

import rectangle from "../../assets/lawyer-assets/backImg.png";
import "./lawyer.css";
// import lawyerImg from "../../assets/images/topLawyer.png";
import lawyer1 from "../../assets/lawyer-assets/lawyer1.png";
import lawyer2 from "../../assets/lawyer-assets/lawyer2.png";
import lawyer3 from "../../assets/lawyer-assets/lawyer3.png";
import lawyer4 from "../../assets/lawyer-assets/lawyer4.png";
import right from "../../assets/Right.png";
import hammer from "../../assets/lawyer-assets/judge-hammer.png";
import MessageIcon from "@mui/icons-material/Message";
// import ReviewStar from "../../Components/ReviewStar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllLaywers } from "../../_redux/features/firm/firmSlice";
import {
  AddInChatListApi,
  chatListApi,
} from "../../_redux/features/messageSlice";
import { Typography, Button } from "@mui/material";
import MainNews from "../Home/MainNews";
import { UpdateClientProfileDetails } from "../../_redux/features/auth/authSlice";
import SearchIcon from "@mui/icons-material/Search";
import Rating from "@mui/material/Rating";

const Lawyers = () => {
  const navigate = useNavigate();
  const { isLoading, lawyerList } = useSelector((state) => state.firm);

  const itemsPerPage = 7;
  const [currentPage, setCurrentPage] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");
  // const [filteredLawyers, setFilteredLawyers] = useState([]);

  let login = JSON.parse(localStorage.getItem("user"));
  const getData = login?.Role?.title;

  const dispatch = useDispatch();

  const getRandomLawyerImage = () => {
    const images = [lawyer1, lawyer2, lawyer3, lawyer4];
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };

  useEffect(() => {
    dispatch(getAllLaywers());
  }, [dispatch]);

  // const handleSearch = () => {
  //   const filtered = lawyerList?.AllLawyersList?.teamLayer?.filter((lawyer) =>
  //     lawyer.name.toLowerCase().includes(searchQuery.toLowerCase())
  //   );
  //   setFilteredLawyers(filtered);
  //   setSearchQuery('')
  // };

  // const lawyersToDisplay = filteredLawyers?.length > 0 ? filteredLawyers : lawyerList?.AllLawyersList?.teamLayer;

  const Lawyers = lawyerList?.AllLawyersList?.teamLayer?.filter((firm) =>
    firm.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Separating firms into two arrays based on plan_price
  const higherRankedFirms = [];
  const alphabeticalFirms = [];

  Lawyers?.forEach((firm) => {
    if (firm.plan_price === "17000") {
      higherRankedFirms.push(firm);
    } else {
      alphabeticalFirms.push(firm);
    }
  });

  // Sorting alphabeticalFirms by name
  alphabeticalFirms?.sort((a, b) => a.name.localeCompare(b.name));

  // Concatenating both arrays for the final display
  const sortedLawyers = [...higherRankedFirms, ...alphabeticalFirms];

  const SendMessageLaywer = (id, e) => {
    e.stopPropagation();

    if (login && getData === "client") {
      navigate("/dashboard/Client/Chat", {
        state: { eventKey: "second", user_id: id },
      });

      const addinlist = async () => {
        await dispatch(
          AddInChatListApi({ recieverId: id, senderId: login?.user?._id })
        ).then((res) => {
          dispatch(chatListApi({ id: login?.user?._id }));
        });
      };
      addinlist();
    } else {
      toast.error(
        "Only clients can send messages. Please log in with your client account to contact your lawyer.",
        { autoClose: 2000 }
      );
    }
  };

  const firmsDetails = async (data) => {
    await dispatch(UpdateClientProfileDetails({ id: data?._id })).then(
      (response) => {
        if (response?.payload?.success === true) {
          let user = response?.payload?.user;
          navigate("/firm-details", {
            state: { cardInfo: user, firmData: data },
          });
          window.scrollTo({ top: 150, behavior: "smooth" });
        }
      }
    );
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const getMonthlyChatAvailability = (planPrice, createdAt) => {
    const currentTime = new Date();
    const firmCreationDate = new Date(createdAt);
    const timeDifferenceInMs = currentTime - firmCreationDate;
    const timeDifferenceInMonths = timeDifferenceInMs / (1000 * 3600 * 24 * 30);

    // Example values for hours per month
    const hoursPerMonthFor17000 = Infinity; // Unlimited for plan price 17000
    const hoursPerMonthFor10000 = 450;
    const hoursPerMonthFor5000 = 150;

    let monthlyChatAvailability = 0;

    if (timeDifferenceInMonths <= 0) {
      return 0; // No chat availability if the firm was created recently
    } else {
      monthlyChatAvailability = Math.floor(timeDifferenceInMonths * 720); // Example value for hours per month

      if (planPrice === "17000") {
        return hoursPerMonthFor17000; // Unlimited chat for plan price 17000
      } else if (planPrice === "10000") {
        return monthlyChatAvailability > hoursPerMonthFor10000
          ? hoursPerMonthFor10000
          : monthlyChatAvailability;
      } else if (planPrice === "5000") {
        return monthlyChatAvailability > hoursPerMonthFor5000
          ? hoursPerMonthFor5000
          : monthlyChatAvailability;
      } else {
        return 0; // Default value for no chat availability
      }
    }
  };

  const [mainNews, setMainNews] = useState("mainNews");

  useEffect(() => {
    setMainNews(mainNews);
  }, [mainNews]);

  const handleNavigate = () => {
    navigate("/contact");
  };
  return (
    <>
      <br></br>
      <div className="">
        <div className="position-relative  mrginTop">
          <div className="container-fluid position-absolute top-0 start-50 translate-middle-x text-center">
            <div className="row mx-5 d-flex align-items-md-center h">
              <div className="col-md-5 col-sm-12 order-md-1 order-sm-2  mt-[130px] flex flex-start flex-col">
                <Typography
                  className="ps-0 text-white text-black"
                  sx={{
                    fontWeight: "300",
                    fontSize: { xs: "30px", sm: "42px", lg: "45px" },
                    fontFamily: "poppins",
                  }}
                >
                  Find the{" "}
                  <span
                    className=""
                    style={{
                      fontWeight: "400",
                    }}
                  >
                    Right
                  </span>
                  <br />
                  <span
                    className=""
                    style={{
                      fontWeight: "400",
                    }}
                  >
                    Lawyer
                  </span>{" "}
                  for you
                </Typography>

                <Typography
                  className="ps-0 text-white text-black "
                  sx={{
                    fontWeight: "400",
                    mt: { xs: "1rem" },
                    fontSize: { xs: "18px", sm: "21px", lg: "21px" },
                    fontFamily: "poppins,sans-serif",
                  }}
                >
                  {" "}
                  Need help but do not know where to start? look no further!
                </Typography>
                <Button
                  sx={{
                    fontWeight: "400",
                    mt: { xs: "1rem" },
                    fontSize: { xs: "18px", sm: "21px", lg: "21px" },
                    fontFamily: "poppins,sans-serif",
                    padding: "7px 25px",
                    background: "white",
                    color: "black",
                    textAlign: "center",
                    width: "200px",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "white",
                    },
                  }}
                  className="btnMobile"
                  // className="text-black btnMobile"
                  onClick={() => handleNavigate()}
                >
                  {" "}
                  Contact Us
                </Button>
                {/* start search filter-------------------- */}
                <div
                  className="container main-input-search"
                  style={{
                    alignItems: "center",
                    left: "50%",
                    top: "100%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    position: "absolute",
                  }}
                >
                  <div
                    className="d-flex align-items-center justify-content-center mx-md-5 mx-0"
                    style={{
                      background: "white",
                    }}
                  >
                    <div className="col-md-12 col-sm-12 date-picker-container ">
                      <input
                        className="p-3 fw-semibold w-100"
                        placeholder="Search Expert Lawyers"
                        type="text"
                        name="input-date"
                        id="input-date"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "18px",
                          color: "black",
                        }}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <SearchIcon
                        className="me-md-5 fs-2"
                        sx={{
                          backgroundColor: "white",
                          color: "black",
                          borderRadius: "50%",
                          padding: "5px",
                          marginLeft: "20px",
                        }}
                      />
                    </div>

                    {/* <div className="">
                <button className="btn btn-lg btn-search mx-5" onClick={handleSearch}>Search</button>
              </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src={rectangle}
            alt=""
            className="img-fluid back-img w-100  back-img-girl "
          />
        </div>

        {/* start lawers card  */}
        <div className="container" style={{ marginTop: "50px" }}>
          <div className="row mx-md-5 mx-2">
            {/* Start Blue card */}
            <div className="row justify-content-center">
              {/* Static Card */}
              <div
                className="col-lg-4 col-md-6 col-sm-12 mb-4 d-flex justify-content-center"
                style={{
                  minWidth: "413px",
                  height: "591px",
                }}
              >
                <div
                  className="w-100 rounded-5 top-main-card"
                  style={{
                    background: "#2E2829",
                    height: "100%",
                    overflow: "hidden",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    className="poppins-600 pt-sm-5 custom-head text-white text-center"
                    variant="h2"
                    sx={{
                      fontSize: {
                        xs: "42px",
                        lg: "50px",
                      },
                      fontWeight: "600",
                      fontFamily: "poppins,sans-serif",
                    }}
                  >
                    Top Lawyers
                  </Typography>
                  <div className="d-flex justify-content-center mt-4">
                    <img src={hammer} alt="" className="img-fluid" />
                  </div>
                  <div className="d-flex justify-content-end mx-4 mt-4 pt-md-0 pt-5">
                    <img
                      className="img-fluid mt-md-1 mt-0"
                      src={right}
                      alt=""
                      style={{ width: 50, height: 50 }}
                    />
                  </div>
                </div>
              </div>

              {/* Dynamically Generated Cards */}
              {isLoading ? (
                <p>Loading...</p>
              ) : sortedLawyers?.length > 0 ? (
                sortedLawyers?.slice(startIndex, endIndex)?.map((item, i) => {
                  const monthlyChatAvailability = getMonthlyChatAvailability(
                    item.plan_price,
                    item.createdAt
                  );
                  const isChatAvailable = monthlyChatAvailability > 0;

                  return (
                    <div
                      key={i}
                      className="col-lg-4 col-md-6 col-sm-12 mb-4 d-flex justify-content-center"
                      style={{
                        minWidth: "413px",
                        height: "591px",
                      }}
                    >
                      <div
                        className="top-main-card position-relative w-100 rounded-4"
                        style={{
                          overflow: "hidden",
                          cursor: "pointer",
                          height: "591px",
                        }}
                        onClick={() => firmsDetails(item)}
                      >
                        {/* Lawyer Image */}
                        <img
                          className="img-fluid w-100 h-100"
                          src={item.image || getRandomLawyerImage()}
                          style={{ objectFit: "cover", background: "#dce4ed" }}
                          alt=""
                        />

                        {/* Card Content */}
                        <div
                          className="position-absolute w-95 text-center"
                          style={{
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            bottom: "20px",
                            left: "20px",
                            right: "20px",
                            padding: "16px",
                            boxSizing: "border-box",
                            borderRadius: "20px",
                          }}
                        >
                          {/* Chat Icon */}
                          {isChatAvailable && login && getData === "client" && (
                            <button
                              className="btn shadow  border-0 position-absolute"
                              style={{
                                top: "10px",
                                right: "10px",
                                zIndex: 10,
                                backgroundColor: "#BCD422F0", // Updated background color
                                padding: "8px", // Optional for spacing inside the button
                              }}
                              onClick={(e) => SendMessageLaywer(item._id, e)}
                            >
                              <MessageIcon
                                style={{
                                  fontSize: "26px",
                                  color: "#4c6a71",
                                }}
                              />
                            </button>
                          )}

                          {/* Main Content */}
                          <div
                            className="fw-bold text-truncate"
                            style={{
                              fontSize: "24px",
                              fontWeight: "500",
                              color: "#2E2829",
                            }}
                            title={item.name || "No Title Added"}
                          >
                            {item.name || "No Title Added"}
                          </div>
                          <div
                            className="text-muted text-truncate"
                            style={{ fontSize: "20px" }}
                          >
                            {item.email || "No Email Added"}
                          </div>
                          <div
                            className="text-muted"
                            style={{ fontSize: "20px" }}
                          >
                            {item.phone || "No Contact Added"}
                          </div>
                          <div className="mt-2">
                            <Rating
                              name="half-rating"
                              defaultValue={5}
                              precision={0.5}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <p className="p-5 fs-2">No Data</p>
                </div>
              )}
            </div>

            {/* end below cards  */}

            <nav
              className="d-flex justify-content-center"
              style={{ marginTop: "5.5rem" }}
            >
              <ul
                className="pagination p-3"
                style={{
                  backgroundColor: "#f3f2f7",
                  fontSize: "1.2rem",
                  borderRadius: "20px",
                  color: "#7a7786",
                }}
              >
                <button
                  className="page-li me-5 p-1"
                  onClick={() =>
                    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                  }
                  disabled={currentPage === 1}
                >
                  <li className="px-1">&lt; Prev</li>
                </button>

                {Array.from(
                  {
                    length: Math.ceil(
                      (lawyerList?.AllLawyersList?.teamLayer?.length || 0) /
                        itemsPerPage
                    ),
                  },
                  (_, i) => (
                    <button
                      key={i}
                      className="page-li px-3"
                      onClick={() => setCurrentPage(i + 1)}
                      disabled={currentPage === i + 1}
                    >
                      <li className="px-1">{i + 1}</li>
                    </button>
                  )
                )}

                <button
                  className="page-li ms-5 okk"
                  onClick={() =>
                    setCurrentPage((prevPage) =>
                      Math.min(
                        prevPage + 1,
                        Math.ceil(
                          (lawyerList?.AllLawyersList?.teamLayer?.length || 0) /
                            itemsPerPage
                        )
                      )
                    )
                  }
                  disabled={
                    currentPage ===
                    Math.ceil(
                      (lawyerList?.AllLawyersList?.teamLayer?.length || 0) /
                        itemsPerPage
                    )
                  }
                >
                  <li className="p-1 px-2">Next &gt;</li>
                </button>
              </ul>
            </nav>
          </div>
        </div>
        {/* end lawers card  */}
      </div>

      <div className="div" style={{ marginTop: "4rem" }}>
        <div className="">
          <Typography
            variant="h1"
            sx={{
              fontSize: {
                xs: "30px",
                lg: "50px",
              },
              textAlign: "center",
              fontWeight: "400",
              mb: {
                xs: "30px",
                lg: "60px",
              },
              fontFamily: "poppins,sans-serif",
            }}
          >
            News & Events
          </Typography>
          <MainNews mainNews={mainNews} />
        </div>
      </div>
    </>
  );
};
export default Lawyers;
