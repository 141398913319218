// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.poppins-100 {
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
  }
  
.poppins-200 {
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
  }
  
.poppins-300 {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
  }
  
.poppins-400 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
  }
  
.poppins-500 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
  }
  
  .poppins-600 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }
  
  .poppins-700 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
  }
  p,h1,h2,h3,h4,h5,label,input,textarea{
    font-family: 'Poppins', sans-serif; 
  }
  li {
    font-size:'20px'
  }
  p,span{
    text-align: left !important;
  }

  

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAKA;IACI,kCAAkC;IAClC,gBAAgB;EAClB;;AAEF;IACI,kCAAkC;IAClC,gBAAgB;EAClB;;AAEF;IACI,kCAAkC;IAClC,gBAAgB;EAClB;;AAEF;IACI,kCAAkC;IAClC,gBAAgB;EAClB;;AAEF;IACI,kCAAkC;IAClC,gBAAgB;EAClB;;EAEA;IACE,kCAAkC;IAClC,gBAAgB;EAClB;;EAEA;IACE,kCAAkC;IAClC,gBAAgB;EAClB;EACA;IACE,kCAAkC;EACpC;EACA;IACE;EACF;EACA;IACE,2BAA2B;EAC7B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');\n\n\n\n\n.poppins-100 {\n    font-family: 'Poppins', sans-serif;\n    font-weight: 100;\n  }\n  \n.poppins-200 {\n    font-family: 'Poppins', sans-serif;\n    font-weight: 200;\n  }\n  \n.poppins-300 {\n    font-family: 'Poppins', sans-serif;\n    font-weight: 300;\n  }\n  \n.poppins-400 {\n    font-family: 'Poppins', sans-serif;\n    font-weight: 400;\n  }\n  \n.poppins-500 {\n    font-family: 'Poppins', sans-serif;\n    font-weight: 500;\n  }\n  \n  .poppins-600 {\n    font-family: 'Poppins', sans-serif;\n    font-weight: 600;\n  }\n  \n  .poppins-700 {\n    font-family: 'Poppins', sans-serif;\n    font-weight: 700;\n  }\n  p,h1,h2,h3,h4,h5,label,input,textarea{\n    font-family: 'Poppins', sans-serif; \n  }\n  li {\n    font-size:'20px'\n  }\n  p,span{\n    text-align: left !important;\n  }\n\n  \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
