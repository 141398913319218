// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .nav-link.active {  
    background-color: #0d0e0f !important;
    border-radius: 1.5rem;
  }
  .nav-link.active span {
color: #ffffff !important; /* White text color */
  } 

  .coom-img {
    height: 70vh;
  }

  @media (max-width:500px) {
    .coom-img {
      height: 30vh;
    }
  }
 
 
`, "",{"version":3,"sources":["webpack://./src/Pages/Lawpage/LawTabs/lawTabs.css"],"names":[],"mappings":";EACE;IACE,oCAAoC;IACpC,qBAAqB;EACvB;EACA;AACF,yBAAyB,EAAE,qBAAqB;EAC9C;;EAEA;IACE,YAAY;EACd;;EAEA;IACE;MACE,YAAY;IACd;EACF","sourcesContent":["\n  .nav-link.active {  \n    background-color: #0d0e0f !important;\n    border-radius: 1.5rem;\n  }\n  .nav-link.active span {\ncolor: #ffffff !important; /* White text color */\n  } \n\n  .coom-img {\n    height: 70vh;\n  }\n\n  @media (max-width:500px) {\n    .coom-img {\n      height: 30vh;\n    }\n  }\n \n \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
