// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination .page-item.active .page-link {
    background-color: rgb(12, 13, 14);
    border-color: rgb(12, 13, 14);
    color: #fff; 
    font-weight: 600;
    border-radius: 5px;
   
  }

  .pagination .page-link {
    background-color: #fff;
    border-color: rgb(12, 13, 14);
    color: rgb(12, 13, 14); 

  }`, "",{"version":3,"sources":["webpack://./src/Pages/Dashboard/DashboardTab/userlist.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,6BAA6B;IAC7B,WAAW;IACX,gBAAgB;IAChB,kBAAkB;;EAEpB;;EAEA;IACE,sBAAsB;IACtB,6BAA6B;IAC7B,sBAAsB;;EAExB","sourcesContent":[".pagination .page-item.active .page-link {\n    background-color: rgb(12, 13, 14);\n    border-color: rgb(12, 13, 14);\n    color: #fff; \n    font-weight: 600;\n    border-radius: 5px;\n   \n  }\n\n  .pagination .page-link {\n    background-color: #fff;\n    border-color: rgb(12, 13, 14);\n    color: rgb(12, 13, 14); \n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
