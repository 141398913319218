// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `



  /* Modal Container */
  .modalCaseHistory {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  /* Modal Content */
  .modal-contentCaseHistory {
    background-color: #fff;
    border-radius: 14px;
    overflow-y: auto;
    padding: 20px;
    max-width: 70%;
    max-height: 90%;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  

  
  
  @media only screen and (max-width: 990px) {
    .modal-contentCaseHistory {
           height:70% ;
    }
 
  }
  `, "",{"version":3,"sources":["webpack://./src/Styles/casehistoryModal.css"],"names":[],"mappings":";;;;EAIE,oBAAoB;EACpB;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,aAAa;IACb,oCAAoC;EACtC;;EAEA,kBAAkB;EAClB;IACE,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,cAAc;IACd,eAAe;;IAEf,uCAAuC;EACzC;;;;;EAKA;IACE;WACO,WAAW;IAClB;;EAEF","sourcesContent":["\n\n\n\n  /* Modal Container */\n  .modalCaseHistory {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 1000;\n    background-color: rgba(0, 0, 0, 0.5);\n  }\n  \n  /* Modal Content */\n  .modal-contentCaseHistory {\n    background-color: #fff;\n    border-radius: 14px;\n    overflow-y: auto;\n    padding: 20px;\n    max-width: 70%;\n    max-height: 90%;\n\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\n  }\n  \n\n  \n  \n  @media only screen and (max-width: 990px) {\n    .modal-contentCaseHistory {\n           height:70% ;\n    }\n \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
