import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Welcome from "./Welcome";
import ChatArea from "./ChatArea";
import { useDispatch, useSelector } from "react-redux";
import { chatListApi } from "../../_redux/features/messageSlice";

const ChatScreen = ({ MessageToUser }) => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("user"));

  const [screenCount, setscreenCount] = useState("welcome");
  const [conversation, setConversation] = useState();
  const { chatList, isLoading } = useSelector((state) => state.message);
  const [conversations, setConversations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const passChatId = (data) => {
    setConversation(data);
  };

  const user = userData.user;
  const getData = async () => {
    await dispatch(chatListApi({ id: userData?.user?._id })).then(
      (response) => {}
    );
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (chatList && chatList.length > 0) {
      const sortedChats = [...chatList].sort((a, b) => {
        const dateA = new Date(a.latestMessage?.createdAt);
        const dateB = new Date(b.latestMessage?.createdAt);
        return dateB - dateA;
      });
      setConversations(sortedChats);
    }
  }, [chatList]);
  const filteredConversations = conversations.filter((conversation) => {
    const validUser = conversation?.users?.find(
      (item) => item._id !== user?._id
    );
    return (
      validUser &&
      validUser.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const putChatOnTop = (chatId) => {
    if (conversations && conversations.length > 0) {
      const updatedConversations = conversations.filter(
        (conversation) => conversation._id !== chatId
      );

      const chatToMove = conversations.find(
        (conversation) => conversation._id === chatId
      );

      if (chatToMove) {
        setConversations([chatToMove, ...updatedConversations]);
      }
    }
  };
  return (
    <>
      <div className="mx-1 mt-4 ">
        {/* <div className="poppins-600 fw-bold " style={{ fontSize: "30px" }}>
          Chat
        </div> */}

        <div className="row mt-md-5  sb-header">
          <div className="col-md-4 col-sm-12">
            <Sidebar
              setscreenCount={setscreenCount}
              sendchatId={passChatId}
              isLoading={isLoading}
              chatList={chatList}
              userData={userData}
              conversations={conversations}
              setConversations={setConversations}
              user={user}
              filteredConversations={filteredConversations}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </div>
          <div className="col-md-8 col-sm-12">
            {screenCount === "welcome" ? (
              <Welcome />
            ) : screenCount === "messages" ? (
              <ChatArea
                setscreenCount={setscreenCount}
                MessageToUser={MessageToUser}
                conversation={conversation}
                setConversation={setConversation}
                setConversations={setConversations}
                conversations={conversations}
                putChatOnTop={putChatOnTop}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatScreen;
