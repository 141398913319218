// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.add.active {
    background-color: transparent !important; 
    color: black !important; 
font-weight: 500;
font-size: 24px !important;
border-bottom: 2px solid red;
width: 50px;
border-radius: 0 !important;
border-bottom: 2px solid #000 !important;
  }
  

  .add {
    color: #5f5254 !important;
    min-width: 210px !important;
    font-size: 21px !important;
  }
  
 

  .add:focus {
    outline: none; /* Remove focus outline if undesired */
  }


  

  .w-1000{
    width: 50%;
  }

  .cardHeading{
    font-size: 56px;
  }

  .cardPara{
    font-size: 21px;
    margin-right: 100px;
  }

  .imgHeight{
    max-height: 70vh;
  }

  .padlig{
    padding-left: 32px;
  }

 
  @media screen and (max-width: 768px) {
    .mobile-class {
      display: flex;
      flex-direction: column-reverse !important;
      justify-content: center;
      align-items: center;
    }

    .padlig{
      padding-left: 0px;
    }

    .w-1000{
      width: 100%;
    }

    .cardHeading{
      font-size: 30px;
      text-align: center;
    }
  
    .imgHeight{
      max-height: 37vh;
    }
    .cardPara{
      font-size: 15px;
      text-align: justify;
      margin-right: 0px;

    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/Firms/FirmDetails/FirmDetailsTabs/tabs.css"],"names":[],"mappings":";AACA;IACI,wCAAwC;IACxC,uBAAuB;AAC3B,gBAAgB;AAChB,0BAA0B;AAC1B,4BAA4B;AAC5B,WAAW;AACX,2BAA2B;AAC3B,wCAAwC;EACtC;;;EAGA;IACE,yBAAyB;IACzB,2BAA2B;IAC3B,0BAA0B;EAC5B;;;;EAIA;IACE,aAAa,EAAE,sCAAsC;EACvD;;;;;EAKA;IACE,UAAU;EACZ;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;EACpB;;;EAGA;IACE;MACE,aAAa;MACb,yCAAyC;MACzC,uBAAuB;MACvB,mBAAmB;IACrB;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,WAAW;IACb;;IAEA;MACE,eAAe;MACf,kBAAkB;IACpB;;IAEA;MACE,gBAAgB;IAClB;IACA;MACE,eAAe;MACf,mBAAmB;MACnB,iBAAiB;;IAEnB;EACF","sourcesContent":["\n.add.active {\n    background-color: transparent !important; \n    color: black !important; \nfont-weight: 500;\nfont-size: 24px !important;\nborder-bottom: 2px solid red;\nwidth: 50px;\nborder-radius: 0 !important;\nborder-bottom: 2px solid #000 !important;\n  }\n  \n\n  .add {\n    color: #5f5254 !important;\n    min-width: 210px !important;\n    font-size: 21px !important;\n  }\n  \n \n\n  .add:focus {\n    outline: none; /* Remove focus outline if undesired */\n  }\n\n\n  \n\n  .w-1000{\n    width: 50%;\n  }\n\n  .cardHeading{\n    font-size: 56px;\n  }\n\n  .cardPara{\n    font-size: 21px;\n    margin-right: 100px;\n  }\n\n  .imgHeight{\n    max-height: 70vh;\n  }\n\n  .padlig{\n    padding-left: 32px;\n  }\n\n \n  @media screen and (max-width: 768px) {\n    .mobile-class {\n      display: flex;\n      flex-direction: column-reverse !important;\n      justify-content: center;\n      align-items: center;\n    }\n\n    .padlig{\n      padding-left: 0px;\n    }\n\n    .w-1000{\n      width: 100%;\n    }\n\n    .cardHeading{\n      font-size: 30px;\n      text-align: center;\n    }\n  \n    .imgHeight{\n      max-height: 37vh;\n    }\n    .cardPara{\n      font-size: 15px;\n      text-align: justify;\n      margin-right: 0px;\n\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
