// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-form-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  @media (min-width: 600px) {
    .register-form-wrapper {
      flex-direction: row;
    }
  
    .form-content {
      width: 50%;
    }
  
    .image-content {
      width: 50%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/Register/register.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE;MACE,mBAAmB;IACrB;;IAEA;MACE,UAAU;IACZ;;IAEA;MACE,UAAU;IACZ;EACF","sourcesContent":[".register-form-wrapper {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  @media (min-width: 600px) {\n    .register-form-wrapper {\n      flex-direction: row;\n    }\n  \n    .form-content {\n      width: 50%;\n    }\n  \n    .image-content {\n      width: 50%;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
