import React, { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { IconButton } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { chatListApi } from "../../_redux/features/messageSlice";
import { chatURL } from "../../_redux/axios/axios";
import "./myStyles.css";

var socket;
function Sidebar({
  setscreenCount,
  sendchatId,
  chatList,
  isLoading,
  userData,
  conversations,
  setConversations,
  user,
  filteredConversations,
  setSearchTerm,
  searchTerm,
}) {
  const ENDPOINT = chatURL;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    socket = io(ENDPOINT);
    socket.emit("setup", userData);
    socket.on("connection", () => {});
  }, []);

  useEffect(() => {
    socket.on("message received", (newMessage) => {
      console.log("testing message received2:", newMessage);

      let userIsValid = false;

      conversations?.forEach((conversation) => {
        const validUser = conversation?.users?.find(
          (item) => item._id === newMessage?.sender._id
        );
      });

      if (!userIsValid) {
        dispatch(chatListApi({ id: userData?.user?._id }));
      }
    });

    return () => {
      socket.off("message received");
    };
  }, [socket]);
  const conversationid = (conversation) => {
    sendchatId(conversation);
  };

  return (
    <div className="sidebar-container ">
      <div className={"sb-header1"}>
        <div
          className="other-icons"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <h3>Add New Chat </h3>

          <IconButton
            onClick={() => {
              navigate("/lawyers");
            }}
          >
            <PersonAddIcon className={"icon"} />
          </IconButton>
        </div>
      </div>
      <div
        className={"sb-search"}
        style={{
          margin: "10px 15px",
        }}
      >
        <IconButton className={"icon"}>
          <SearchIcon />
        </IconButton>
        <input
          placeholder="Search"
          className={"search-box"}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div
        className={"sb-conversations"}
        style={{
          borderTop: "1px solid #D0D0D0",
        }}
      >
        {
          // isLoading ? (
          //   <p>Loading..3</p>
          // ) :
          filteredConversations?.length === 0 ? (
            <h2 className="d-flex justify-content-center p-5">No Data</h2>
          ) : (
            filteredConversations?.map((conversation, index) => {
              const validUser = conversation?.users?.find(
                (item) => item._id !== user?._id
              );

              if (conversation.users.length === 1) {
                return <div key={index}></div>;
              }
              if (conversation.latestMessage === undefined) {
                return (
                  <div key={index} onClick={() => {}}>
                    <div
                      key={index}
                      className="conversation-container"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setscreenCount("messages");
                        conversationid(conversation);
                      }}
                    >
                      <div className={"con-icon"}>
                        {conversation.users[0]._id === user?._id ? (
                          conversation?.users?.[1]?.image ? (
                            <img
                              src={conversation?.users?.[1]?.image}
                              className="img-fluid rounded-circle w-100 h-100"
                              alt="No Pic"
                            />
                          ) : (
                            <span>{conversation.users[1].name?.[0]}</span>
                          )
                        ) : conversation?.users?.[0]?.image ? (
                          <img
                            src={conversation?.users?.[0]?.image}
                            className="img-fluid rounded-circle w-100 h-100"
                            alt="No Pic"
                          />
                        ) : (
                          <span>{conversation.users[0].name?.[0]}</span>
                        )}
                      </div>

                      <p className={"con-title"}>
                        {conversation.users[0]._id == user?._id
                          ? conversation.users[1].name
                          : conversation.users[0].name}
                      </p>

                      <p className="con-lastMessage">
                        No previous Messages, click here to start a new chat
                      </p>
                      {/* <p className={"con-timeStamp" }>
                {conversation.timeStamp}
              </p> */}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    key={index}
                    className="conversation-container"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setscreenCount("messages");
                      conversationid(conversation);
                    }}
                  >
                    {/* <p className={"con-icon"}>{validUser.name[0]}</p> */}
                    <img
                      className={"con-icon"}
                      src="https://w7.pngwing.com/pngs/910/606/png-transparent-head-the-dummy-avatar-man-tie-jacket-user-thumbnail.png"
                      alt="dummy"
                    ></img>

                    <p className="con-title">
                      {validUser ? validUser.name : "no name"}
                    </p>

                    <p className="con-lastMessage">
                      {conversation?.latestMessage?.content}
                    </p>
                    {/* <p className={"con-timeStamp" }>
                {conversation.timeStamp}
              </p> */}
                  </div>
                );
              }
            })
          )
        }
      </div>
    </div>
  );
}

export default Sidebar;
