// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
    .col-md-6 .imgg_1 {
        display: none;
    }

    .hero_img {
        height: 340px !important;

    }

    .about_heading {
        font-size: 30px !important;
        margin-bottom: 10px;
        text-align: center;
    }

    .about_para {
        font-size: 17px !important;
        color: #63666A;
        text-align: justify;
    }
    .img-responsive{
        height: 375px;

    }
}

.about_heading {
    font-size: 56px;
    margin-bottom: 10px;
}

.about_para {
    font-size: 24px;
    color: #63666A;
    text-align: justify;
}

.col-md-6 .imgg_1 {
    height: 500px;
}

.about_img {
    height: 375px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/about-us/about.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,wBAAwB;;IAE5B;;IAEA;QACI,0BAA0B;QAC1B,mBAAmB;QACnB,kBAAkB;IACtB;;IAEA;QACI,0BAA0B;QAC1B,cAAc;QACd,mBAAmB;IACvB;IACA;QACI,aAAa;;IAEjB;AACJ;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":["@media (max-width: 768px) {\n    .col-md-6 .imgg_1 {\n        display: none;\n    }\n\n    .hero_img {\n        height: 340px !important;\n\n    }\n\n    .about_heading {\n        font-size: 30px !important;\n        margin-bottom: 10px;\n        text-align: center;\n    }\n\n    .about_para {\n        font-size: 17px !important;\n        color: #63666A;\n        text-align: justify;\n    }\n    .img-responsive{\n        height: 375px;\n\n    }\n}\n\n.about_heading {\n    font-size: 56px;\n    margin-bottom: 10px;\n}\n\n.about_para {\n    font-size: 24px;\n    color: #63666A;\n    text-align: justify;\n}\n\n.col-md-6 .imgg_1 {\n    height: 500px;\n}\n\n.about_img {\n    height: 375px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
