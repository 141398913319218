import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import IMG from "../../../assets/file/img.png";
import { Tooltip } from "@mui/material";

import {
  UpdateService,
  getServicesByID,
} from "../../../_redux/features/firm/firmSlice";

import { toast } from "react-toastify";

const EditCaseScreen = ({ item, closeModal, Id, ModalIsOpen }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [addServices, setAddServices] = useState({
    id: item._id,
    title: item?.title || "",
    description: item?.description || "",
    image: item?.image || null,
  });

  const [errors, setErrors] = useState({
    id: item._id,
    title: "",
    description: "",
    image: null,
  });
  useEffect(() => {
    setAddServices({
      id: item._id,
      title: item?.title || "",
      description: item?.description || "",
      image: item?.image || null,
    });
  }, [item]);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.firm);

  const handleChange = (e) => {
    setAddServices((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: "",
    }));
  };

  const fileInputRef = useRef(null);

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setAddServices((prevState) => ({
      ...prevState,
      image: file,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      image: "",
    }));
  };

  const validateFields = () => {
    const newErrors = {};
    if (!addServices.title) {
      newErrors.title = "Title is required";
    }
    if (!addServices.description) {
      newErrors.description = "Description is required";
    }
    if (!addServices?.image) {
      newErrors.image = "Image is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const HandleUpdateService = async () => {
    if (!validateFields()) {
      toast.error("Please fill in all required fields!", { autoClose: 2000 });
      return;
    }

    const formData = new FormData();
    for (let key in addServices) {
      formData.append(key, addServices[key]);
    }

    await dispatch(UpdateService(formData)).then(async (res) => {
      toast.success(res.payload?.message, {
        autoClose: 1000,
      });
      await dispatch(getServicesByID({ user_id: user?.user?._id }));
      closeModal();
    });
  };

  return (
    <>
      <Modal
        isOpen={ModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {/* Close buttom */}
        <div className="row justify-content-end">
          <button className="btn-close" onClick={closeModal}></button>
        </div>
        {/* Heading */}
        <div className="row text-start">
          <p className="poppins-600 h3">Edit Service</p>
        </div>

        {/* main form */}

        <div className="row mt-0 d-flex justify-content-between align-items-center">
          <div className="col-md-7 col-sm-12">
            <div className="row text-start">
              <div className="form-group mt-3">
                <label htmlFor="title" className="poppins-500">
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  className="form-control my-2"
                  style={{ outline: "none" }}
                  onChange={handleChange}
                  value={addServices.title}
                />
                {errors.title && <p className="text-danger">{errors.title}</p>}
              </div>

              <div className="form-group my-4">
                <label htmlFor="description" className="poppins-500">
                  Description
                </label>
                <textarea
                  className="form-control my-2"
                  style={{ outline: "none" }}
                  name="description"
                  rows="6"
                  value={addServices.description}
                  onChange={handleChange}
                ></textarea>
                {errors.description && (
                  <p className="text-danger">{errors.description}</p>
                )}
              </div>
            </div>
          </div>
          <div
            className="col-md-5 col-sm-12"
            onClick={handleAvatarClick}
            style={{ cursor: "pointer" }}
          >
            <Tooltip title="Select File">
              <img
                src={
                  addServices?.image instanceof File
                    ? URL.createObjectURL(addServices.image)
                    : addServices?.image || IMG
                }
                className="img-circle img-fluid img-thumbnail"
                style={{
                  width: "250px",
                  height: "250px",
                  objectFit: "cover",
                }}
                alt="File Preview"
              />
            </Tooltip>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept="image/*"
            />
            {errors.image && <p className="text-danger">{errors.image}</p>}
          </div>
        </div>

        {/* Submit buttom */}
        <div className="text-center mt-4 mb-4">
          <button className="btn btn-dark" onClick={HandleUpdateService}>
            Update
          </button>
        </div>
      </Modal>
    </>
  );
};

export default EditCaseScreen;
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "4rem",
    borderRadius: "20px",
    transform: "translate(-50%, -50%)",
  },
};
