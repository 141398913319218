import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServicesByID } from "../../../_redux/features/firm/firmSlice";
import Modal from "react-modal";

export default function Services({ firmData }) {
  const { isLoading } = useSelector((state) => state.firm);

  const [services, setServices] = useState([]);
  console.log("🚀 ~ Services ~ services:", services);

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      await dispatch(getServicesByID({ user_id: firmData?._id })).then(
        (res) => {
          setServices(res?.payload?.data);
        }
      );
    };
    getData();
  }, [firmData?._id, dispatch]);

  const closeModal = () => {
    setOpenModal(!openModal);
  };

  const [openModal, setOpenModal] = useState(false);
  const [texting, setText] = useState("");

  const ShowText = (text) => {
    const resultString = text.join(" ");
    setOpenModal(true);
    setText(resultString);
  };

  return (
    <>
      <div className="row">
        {isLoading ? (
          <p>Loading...</p>
        ) : firmData?.plan_price === "17000" ? (
          services?.map((item, i) => {
            const descriptionWords = item?.description.split(" ");
            const truncatedDescription = descriptionWords
              ?.slice(0, 12)
              .join(" ");
            return (
              <div
                className="col-md-3 col-sm-12 mt-md-4  px-sm-0 ms-sm-3 mb-5"
                key={i}
                style={{
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <div className="px-1 px-md-0 news-main-image">
                  <img
                    className="img-fluid rounded-0 w-100 h-100 img-bor border"
                    src={
                      item?.image?.length > 0
                        ? item.image
                        : "https://www.energy.gov/sites/default/files/2018/08/f54/news24.jpg"
                    }
                    style={{ objectFit: "cover" }}
                    alt=""
                  />
                </div>

                <div
                  className="hide mx-auto owner-name"
                  style={{
                    backgroundColor: "black",
                    textAlign: "center",
                    minHeight: "140px",
                    position: "relative",
                    bottom: "8.7rem",
                    opacity: "75%",
                    zIndex: 0,
                  }}
                >
                  <div className="d-flex">
                    <div className="w-100">
                      <div
                        className="text-white"
                        style={{
                          fontFamily: "poppins",
                          textTransform: "capitalize",
                          fontSize: "24px",
                        }}
                      >
                        {item.title ? item.title : "No Title Added"}
                      </div>
                      <div
                        className="text-white mt-md-3 mx-md-3"
                        style={{
                          fontFamily: "poppins",
                          textTransform: "capitalize",
                          // textAlign: "justify",
                          fontSize: "15px",
                        }}
                      >
                        {truncatedDescription
                          ? truncatedDescription
                          : "No Description Added"}
                        <br />
                        <button onClick={() => ShowText(descriptionWords)}>
                          See more ...
                        </button>
                      </div>
                      <div className=""></div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : firmData?.plan_price === "10000" ? (
          services?.slice(0, 3)?.map((item, i) => {
            return (
              <div className="col-md-4 col-sm-4 my-3">
                <div
                  style={{
                    backgroundImage: `url(${item.image})`,
                    borderRadius: "10px",
                    backgroundSize: "cover",

                    backgroundRepeat: "no-repeat",
                    height: "350px",
                  }}
                >
                  <div className="d-flex h-100 align-items-end justify-content-center">
                    <div
                      className=" d-flex text-center p-3  rounded-3 w-100 p-2"
                      style={{ backgroundColor: "#000000a1" }}
                    >
                      {/* <p
               style={{
                color:'white',
                fontWeight:"semi bold",
                fontSize:"24px"

              }}
              className='poppins-300'>{item.type} : </p> */}
                      <p
                        className=" h5 poppins-300"
                        style={{
                          color: "white",
                          fontWeight: "semi bold",
                          fontSize: "24px",
                        }}
                      >
                        {" "}
                        {item.title?.slice(0, 20)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : firmData?.plan_price === "5000" ? (
          services?.slice(0, 1)?.map((item, i) => {
            return (
              <div className="col-md-4 col-sm-4 my-3">
                <div
                  style={{
                    backgroundImage: `url(${item.image})`,
                    borderRadius: "10px",
                    backgroundSize: "cover",

                    backgroundRepeat: "no-repeat",
                    height: "350px",
                  }}
                >
                  <div className="d-flex h-100 align-items-end justify-content-center">
                    <div
                      className=" d-flex text-center p-3  rounded-3 w-100 p-2"
                      style={{ backgroundColor: "#000000a1" }}
                    >
                      {/* <p
               style={{
                color:'white',
                fontWeight:"semi bold",
                fontSize:"24px"

              }}
              className='poppins-300'>{item.type} : </p> */}
                      <p
                        className=" h5 poppins-300"
                        style={{
                          color: "white",
                          fontWeight: "semi bold",
                          fontSize: "24px",
                        }}
                      >
                        {" "}
                        {item.title?.slice(0, 20)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <h2
            className="p-5 text-danger poppins-500 d-flex justify-content-center"
            style={{ textTransform: "capitalize", fontSize: "18px" }}
          >
            We will provide shortly
          </h2>
        )}
      </div>
      {openModal ? (
        <ShowTextModal
          dltId={texting}
          openModal={openModal}
          closeModal={closeModal}
        />
      ) : null}
    </>
  );
}
function ShowTextModal({ dltId, openModal, closeModal }) {
  return (
    <>
      <Modal
        isOpen={openModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="card border-0 p-4">
          <p className="textArea">{dltId}</p>

          <div className="d-flex justify-content-center mt-md-5">
            <div className="mx-3">
              <button
                type="button"
                className="btn btn-light px-4 border poppins-500"
                onClick={() => closeModal()}
              >
                Close
              </button>
            </div>
            {/* <div className="mx-3">
              <button className="btn btn-dark px-4 poppins-500">Delete</button>
            </div> */}
          </div>
        </div>
      </Modal>
    </>
  );
}
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "4rem",
    zIndex: 9999,
    borderRadius: "20px",
    transform: "translate(-50%, -50%)",
  },
};
